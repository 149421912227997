// frontend/src/components/Professional/ProfileCustomization.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Button, TextField, Input } from '@material-ui/core';
import { useSnackbar } from 'notistack';

function ProfileCustomization() {
  const { enqueueSnackbar } = useSnackbar();
  const [profile, setProfile] = useState({
    bio: '',
    profilePicture: null,
    portfolio: [],
  });

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/professionals/profile', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProfile(response.data);
    };
    fetchProfile();
  }, []);

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setProfile({ ...profile, profilePicture: e.target.files[0] });
  };

  const handleSave = async () => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('bio', profile.bio);
    if (profile.profilePicture) {
      formData.append('profilePicture', profile.profilePicture);
    }
    try {
      await axios.post('/api/professionals/update-profile', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      enqueueSnackbar('Perfil atualizado com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Erro ao atualizar perfil.', { variant: 'error' });
    }
  };

  return (
    <Container>
      <Typography variant="h4">Personalizar Perfil</Typography>
      <TextField
        name="bio"
        label="Bio"
        multiline
        rows={4}
        fullWidth
        value={profile.bio}
        onChange={handleChange}
      />
      <Input type="file" name="profilePicture" onChange={handleFileChange} />
      <Button variant="contained" color="primary" onClick={handleSave}>
        Salvar
      </Button>
    </Container>
  );
}

export default ProfileCustomization;

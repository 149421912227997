// frontend/src/components/Notifications/NotificationList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { List, ListItem, ListItemText, Button } from '@material-ui/core';
import io from 'socket.io-client';
import { useSnackbar } from 'notistack';

function NotificationList() {
  const { enqueueSnackbar } = useSnackbar();
  const [notifications, setNotifications] = useState([]);
  const token = localStorage.getItem('token');
  const socket = io(process.env.REACT_APP_SOCKET_URL, {
    query: { token },
  });

  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await axios.get('/api/notifications', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNotifications(response.data);
    };
    fetchNotifications();

    socket.on('notification', (notification) => {
      setNotifications((prevNotifications) => [notification, ...prevNotifications]);
      enqueueSnackbar(notification.message, { variant: 'info' });
    });

    return () => {
      socket.disconnect();
    };
  }, [token, enqueueSnackbar]);

  const markAsRead = async () => {
    await axios.put('/api/notifications/mark-as-read', null, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setNotifications(notifications.map((n) => ({ ...n, isRead: true })));
  };

  return (
    <div>
      <Button onClick={markAsRead}>Marcar todas como lidas</Button>
      <List>
        {notifications.map((notification) => (
          <ListItem key={notification._id} selected={!notification.isRead}>
            <ListItemText
              primary={notification.message}
              secondary={new Date(notification.createdAt).toLocaleString()}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default NotificationList;

// src/components/Professional/Availability.js
import React, { useState } from 'react';
import axios from 'axios';

function Availability() {
  const [availableTimes, setAvailableTimes] = useState([]);

  const handleAddAvailability = () => {
    // Lógica para adicionar disponibilidade
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        '/api/professionals/update-availability',
        { availableTimes },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert('Disponibilidade atualizada com sucesso!');
    } catch (error) {
      alert('Erro ao atualizar disponibilidade.');
    }
  };

  return (
    <div>
      <h2>Definir Disponibilidade</h2>
      {/* Implementação do calendário e seleção de horários */}
      <button onClick={handleSubmit}>Salvar</button>
    </div>
  );
}

export default Availability;

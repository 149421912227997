// frontend/src/theme.js
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5', // Cor primária personalizada
    },
    secondary: {
      main: '#f50057', // Cor secundária personalizada
    },
  },
  typography: {
    // Configurações de tipografia personalizadas
  },
});

export default theme;

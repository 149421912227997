// frontend/src/components/Professional/ProfessionalDashboard.js
import React from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import DocumentUpload from './DocumentUpload';
import Availability from './Availability';
import NotificationList from '../Notifications/NotificationList';
import ProfileCustomization from './ProfileCustomization';
import Support from '../Support/Support';

function ProfessionalDashboard() {
  return (
    <Container>
      <Typography variant="h4">Painel do Profissional</Typography>
      <Button variant="contained" color="primary" href="/professional-dashboard/documents">
        Enviar Documentos
      </Button>
      <Button variant="contained" color="primary" href="/professional-dashboard/availability">
        Definir Disponibilidade
      </Button>
      <Button variant="contained" color="primary" href="/professional-dashboard/profile">
        Personalizar Perfil
      </Button>
      <NotificationList />
      <Support />
      {/* Outras funcionalidades */}
    </Container>
  );
}

export default ProfessionalDashboard;

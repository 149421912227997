// frontend/src/components/Auth/ConfirmAccount.js
import React, { useState } from 'react';
import axios from 'axios';
import { Container, Typography, TextField, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

function ConfirmAccount() {
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState({ email: '', confirmationCode: '' });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleConfirm = async () => {
    try {
      await axios.post('/api/auth/confirm-code', form);
      enqueueSnackbar('Conta confirmada com sucesso! Faça login.', { variant: 'success' });
      window.location.href = '/login';
    } catch (error) {
      enqueueSnackbar('Erro ao confirmar código.', { variant: 'error' });
    }
  };

  return (
    <Container>
      <Typography variant="h4">Confirmar Conta</Typography>
      <TextField name="email" label="E-mail" fullWidth onChange={handleChange} />
      <TextField name="confirmationCode" label="Código de Confirmação" fullWidth onChange={handleChange} />
      <Button variant="contained" color="primary" onClick={handleConfirm}>
        Confirmar
      </Button>
    </Container>
  );
}

export default ConfirmAccount;

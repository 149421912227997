// frontend/src/components/Chat/Chat.js
import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { TextField, Button, Container, List, ListItem, Typography } from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';

function Chat({ match }) {
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const roomId = match.params.roomId;
  const token = localStorage.getItem('token');
  const socket = io(process.env.REACT_APP_SOCKET_URL, {
    query: { token },
  });

  useEffect(() => {
    const fetchMessages = async () => {
      const response = await axios.get(`/api/chat/messages/${roomId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMessages(response.data);
    };
    fetchMessages();

    socket.emit('joinRoom', { roomId });

    socket.on('receiveMessage', (message) => {
      setMessages((oldMessages) => [...oldMessages, message]);
    });

    socket.on('errorMessage', (error) => {
      enqueueSnackbar(error, { variant: 'error' });
    });

    return () => {
      socket.disconnect();
    };
  }, [roomId, token, enqueueSnackbar, socket]);

  const sendMessage = () => {
    const forbiddenPatterns = /(\b\d{8,}\b)|(\b\w+@\w+\.\w+\b)/g;
    if (forbiddenPatterns.test(message)) {
      enqueueSnackbar('Por segurança, não compartilhe informações pessoais como números de telefone ou e-mails neste chat.', { variant: 'warning' });
      return;
    }
    if (message.trim() !== '') {
      socket.emit('sendMessage', { roomId, message, senderId: 'user-id' });
      setMessage('');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Chat
      </Typography>
      <List>
        {messages.map((msg, index) => (
          <ListItem key={index}>{msg.content}</ListItem>
        ))}
      </List>
      <TextField
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Digite sua mensagem"
        fullWidth
        margin="normal"
      />
      <Button onClick={sendMessage} variant="contained" color="primary">
        Enviar
      </Button>
    </Container>
  );
}

export default Chat;

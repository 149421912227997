// src/components/User/UserDashboard.js
import React from 'react';

function UserDashboard() {
  return (
    <div>
      <h1>Dashboard do Usuário</h1>
      {/* Conteúdo adicional */}
    </div>
  );
}

export default UserDashboard;

// src/components/Admin/AdminDashboard.js
import React from 'react';

function AdminDashboard() {
  return (
    <div>
      <h1>Dashboard do Administrador</h1>
      {/* Conteúdo adicional */}
    </div>
  );
}

export default AdminDashboard;

// frontend/src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ConfirmAccount from './components/Auth/ConfirmAccount';
import Home from './components/Others/Home';
import ProfessionalDashboard from './components/Professional/ProfessionalDashboard';
import UserDashboard from './components/User/UserDashboard';
import AdminDashboard from './components/Admin/AdminDashboard';
import Chat from './components/Chat/Chat';
import Support from './components/Support/Support';
import PrivateRoute from './components/Common/PrivateRoute';
import { useTranslation } from 'react-i18next';

function App() {
  const { t } = useTranslation();

  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/confirm" component={ConfirmAccount} />
        <PrivateRoute path="/professional-dashboard" component={ProfessionalDashboard} />
        <PrivateRoute path="/user-dashboard" component={UserDashboard} />
        <PrivateRoute path="/admin-dashboard" component={AdminDashboard} />
        <PrivateRoute path="/chat/:roomId" component={Chat} />
        <PrivateRoute path="/support" component={Support} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export default App;

// frontend/src/components/Auth/Register.js
import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

function Register() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
    city: '',
    state: '',
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/auth/register', form);
      enqueueSnackbar('Registro realizado com sucesso! Verifique seu e-mail para confirmar a conta.', { variant: 'success' });
      window.location.href = '/login';
    } catch (error) {
      enqueueSnackbar('Erro ao registrar.', { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        {t('register')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField label={t('name')} name="name" fullWidth margin="normal" onChange={handleChange} required />
        <TextField label={t('email')} name="email" type="email" fullWidth margin="normal" onChange={handleChange} required />
        <TextField label={t('password')} name="password" type="password" fullWidth margin="normal" onChange={handleChange} required />
        <TextField label={t('city')} name="city" fullWidth margin="normal" onChange={handleChange} required />
        <TextField label={t('state')} name="state" fullWidth margin="normal" onChange={handleChange} required />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {t('register')}
        </Button>
      </form>
    </Container>
  );
}

export default Register;

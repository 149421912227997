// frontend/src/components/Others/Home.js
import React from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Container>
      <Typography variant="h4">{t('welcome')}</Typography>
      <Button variant="contained" color="primary" href="/login">
        {t('login')}
      </Button>
      <Button variant="contained" color="secondary" href="/register">
        {t('register')}
      </Button>
      <Button onClick={() => changeLanguage('pt')}>Português</Button>
      <Button onClick={() => changeLanguage('en')}>English</Button>
    </Container>
  );
}

export default Home;

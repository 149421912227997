// frontend/src/components/Auth/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

function Login() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login', form);
      localStorage.setItem('token', response.data.token);
      if (response.data.user.isAdmin) {
        window.location.href = '/admin-dashboard';
      } else if (response.data.user.isProfessional) {
        window.location.href = '/professional-dashboard';
      } else {
        window.location.href = '/user-dashboard';
      }
    } catch (error) {
      enqueueSnackbar('Erro ao fazer login.', { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        {t('login')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label={t('email')}
          name="email"
          type="email"
          fullWidth
          margin="normal"
          onChange={handleChange}
          required
        />
        <TextField
          label={t('password')}
          name="password"
          type="password"
          fullWidth
          margin="normal"
          onChange={handleChange}
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {t('login')}
        </Button>
      </form>
    </Container>
  );
}

export default Login;

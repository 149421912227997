// src/components/Professional/DocumentUpload.js
import React, { useState } from 'react';
import axios from 'axios';

function DocumentUpload() {
  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    for (let file of files) {
      formData.append('documents', file);
    }

    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/professionals/upload-documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Documentos enviados com sucesso!');
    } catch (error) {
      alert('Erro ao enviar documentos.');
    }
  };

  return (
    <div>
      <h2>Enviar Documentos</h2>
      <input type="file" multiple onChange={handleFileChange} />
      <button onClick={handleUpload}>Enviar</button>
    </div>
  );
}

export default DocumentUpload;

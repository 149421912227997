// frontend/src/components/Support/Support.js
import React, { useState } from 'react';
import axios from 'axios';
import { Container, Typography, TextField, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

function Support() {
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState({ subject: '', message: '' });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.post('/api/support/create-ticket', form, {
        headers: { Authorization: `Bearer ${token}` },
      });
      enqueueSnackbar('Ticket de suporte enviado com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Erro ao enviar ticket de suporte.', { variant: 'error' });
    }
  };

  return (
    <Container>
      <Typography variant="h4">Suporte</Typography>
      <TextField
        name="subject"
        label="Assunto"
        fullWidth
        onChange={handleChange}
      />
      <TextField
        name="message"
        label="Mensagem"
        multiline
        rows={4}
        fullWidth
        onChange={handleChange}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Enviar
      </Button>
    </Container>
  );
}

export default Support;
